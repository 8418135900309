import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App/App';
import BrowserUnsupported from './components/BrowserUnsupported/BrowserUnsupported';
import DailyIframe from '@daily-co/daily-js';

import { autoClickFromPageUrl, drNameFromPageUrl } from './urlUtils';
const autoclick = autoClickFromPageUrl();
const dr = drNameFromPageUrl();
export  { autoclick, dr }

ReactDOM.render(
DailyIframe.supportedBrowser().supported ? <App /> : <BrowserUnsupported />,
document.getElementById('root')
);
