import React, { useEffect } from 'react';
import './StartButton.css';

/**
 * Props:
 * autoclick=props.autoclick;
 * dr=props.dr;
 * - disabled: boolean
 * - onClick: () => ()
 */

function onTimerClick(autoclick) {
	if (autoclick) {
		var myDiv = document.getElementById('clickme');
		myDiv.click();
	}
}

export default function StartButton(props) {
  const autoclick=props.autoclick;
  const dr=props.dr;
  const countdown=10000;

	useEffect(() => {
	  const timer = setTimeout(() => {
			  onTimerClick(autoclick);
			  }, countdown);
	  return () => clearTimeout(timer);
	}, []);

	if (autoclick) {
		  return (
			<button
			id='clickme'
			  className="start-button"
			  disabled={props.disabled}
			  onClick={props.onClick}
			>
			<span class="ico"></span>
			PageTime Call With {props.dr} Will Start in 10 Seconds.
			<span> </span> 
			</button>
		  );
	} else {
		  return (
			<button
			id='clickme'
			  className="start-button"
			  disabled={props.disabled}
			  onClick={props.onClick}
			>
			<span class="ico"></span>
			Click To Start A PageTime Call.
			</button>
		  );
	}
}
